import React from "react"
import "./index.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

class Social extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section className="social">
        <ul className="social__list">
          <a href="https://instagram.com/bosphorus.restaurant/" target="_blank">
            <li>
              <FontAwesomeIcon icon={faInstagram} />
            </li>
          </a>
          <a
            href="https://www.facebook.com/bosphorusrestaurant.at"
            target="_blank"
          >
            <li>
              <FontAwesomeIcon icon={faFacebook} />
            </li>
          </a>
        </ul>
      </section>
    )
  }
}

export default Social
