import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import logo from "./../../images/logo/logo.png"
import "./index.scss"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuToggled: false,
      blackedOut: false,
    }

    this.handleMenuToggle = this.handleMenuToggle.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll(e) {
    if (!this.state.blackedOut) {
      this.setState(state => ({
        blackedOut: true,
      }))
    }
  }

  handleMenuToggle() {
    this.setState(state => ({
      menuToggled: !state.menuToggled,
    }))
  }

  handleClick() {
    this.setState(state => ({
      blackedOut: false,
      menuToggled: false,
    }))
  }

  render() {
    return (
      <header
        className={this.state.blackedOut ? "header blackedOut" : "header"}
      >
        <section className="header__logo">
          <Link to="/">
            <img onClick={this.handleClick} src={logo}></img>
          </Link>
        </section>
        <section className="header__navigation">
          <ul
            className={
              this.state.menuToggled
                ? "header__navigation__list active"
                : "header__navigation__list"
            }
          >
            <li>
              <Link to="/speisekarte">Speisekarte</Link>
            </li>
            <li>
              <Link to="/uber-uns">Über Uns</Link>
            </li>
            <li>
              <Link to="/kontakt">Kontakt</Link>
            </li>
            <li>
              <a href="tel:+43528872299">Jetzt Reservieren!</a>
            </li>
          </ul>
          <div
            className={
              this.state.menuToggled ? "hamburger active" : "hamburger"
            }
            onClick={this.handleMenuToggle}
          ></div>
        </section>
      </header>
    )
  }
}

export default Header
